const enums = {
  cmpyTypeEnum: [
    {
      key: "98",
      text: "医院/卫生院",
    },
    {
      key: "99",
      text: "学校",
    },
    {
      key: "2",
      text: "富士康",
    },
    {
      key: "3",
      text: "比亚迪",
    },
    {
      key: "4",
      text: "河南省双扶建筑安装有限公司",
    },
    {
      key: "5",
      text: "河南省顺和丰供应链管理有限公司",
    },
    {
      key: "6",
      text: "河南京晟建设工程集团有限公司",
    },
    {
      key: "7",
      text: "河南华鼎供应链管理有限公司",
    },
    {
      key: "8",
      text: "河南极兔极致供应链有限公司",
    },
    {
      key: "9",
      text: "郑州统一企业有限公司",
    },
    {
      key: "10",
      text: "河南战友物业管理有限公司",
    },
    {
      key: "11",
      text: "河南良品铺子食品有限公司",
    },
    {
      key: "12",
      text: "河南汇海物流有限公司",
    },
    {
      key: "13",
      text: "河南中平恒达实业有限公司",
    },
    {
      key: "14",
      text: "河南宝盈建设集团有限公司",
    },
    {
      key: "15",
      text: "河南航空货运发展有限公司",
    },
    {
      key: "16",
      text: "中国南方航空河南航空有限公司",
    },
    {
      key: "17",
      text: "河南省机场集团有限公司",
    },
    {
      key: "18",
      text: "河南郑州新郑国际机场有限公司",
    },
    {
      key: "19",
      text: "河南圆方人力资源管理有限公司",
    },
    {
      key: "20",
      text: "河南鹏劳人力资源管理有限公司",
    },
    {
      key: "21",
      text: "河南正源咨询代理服务有限公司",
    },
    {
      key: "22",
      text: "河南省视博电子股份有限公司",
    },
    {
      key: "23",
      text: "郑州联创电子有限公司",
    },
    {
      key: "24",
      text: "郑州领胜科技有限公司",
    },
    {
      key: "25",
      text: "河南省华锐光电产业有限公司",
    },
    {
      key: "26",
      text: "河南众信智能装备服务有限公司",
    },
    {
      key: "27",
      text: "河南省华锐智能光显有限公司",
    },
    {
      key: "28",
      text: "合众智造（河南）科技有限公司",
    },
    {
      key: "29",
      text: "中原龙浩航空有限公司",
    },
    {
      key: "97",
      text: "其他",
    },
  ],
  eduEnum: [
    {
      key: "1",
      text: "绿苑幼儿园",
      name: "郑州航空港区绿苑幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "2",
      text: "航南幼儿园",
      name: "郑州航空港区航南幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "3",
      text: "田园新城幼儿园",
      name: "郑州航空港区田园新城幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "4",
      text: "裕兴幼儿园",
      name: "郑州航空港区裕兴幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "5",
      text: "裕丰幼儿园",
      name: "郑州航空港区裕丰幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "6",
      text: "航南新城第一幼儿园",
      name: "郑州航空港区航南新城第一幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "7",
      text: "裕华幼儿园",
      name: "郑州航空港区裕华幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "8",
      text: "枣园幼儿园",
      name: "郑州航空港区枣园幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "9",
      text: "翠园幼儿园",
      name: "郑州航空港区翠园幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "10",
      text: "荷园幼儿园",
      name: "郑州航空港区荷园幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "11",
      text: "豫发国园幼儿园",
      name: "郑州航空港区豫发国园幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "12",
      text: "大马幼儿园",
      name: "郑州航空港区大马幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "13",
      text: "张庄幼儿园",
      name: "郑州航空港区张庄幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "14",
      text: "宋庄幼儿园",
      name: "郑州航空港区宋庄幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "15",
      text: "吕坡幼儿园",
      name: "郑州航空港区吕坡幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "16",
      text: "龙港幼儿园",
      name: "郑州航空港区龙港幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "17",
      text: "启智幼儿园",
      name: "郑州航空港区启智幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "18",
      text: "常店幼儿园",
      name: "郑州航空港区常店幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "19",
      text: "河头陈幼儿园",
      name: "郑州航空港区河头陈幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "20",
      text: "白庄幼儿园",
      name: "郑州航空港区白庄幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "21",
      text: "三官庙幼儿园",
      name: "郑州航空港区三官庙幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "22",
      text: "盛景苑幼儿园",
      name: "郑州航空港区盛景苑幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "23",
      text: "空港美丽港湾幼儿园",
      name: "郑州航空港区空港美丽港湾幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "24",
      text: "空港幸福佳苑幼儿园",
      name: "郑州航空港区空港幸福佳苑幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "25",
      text: "龙王幼儿园",
      name: "郑州航空港区龙王幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "26",
      text: "园博美丽新城幼儿园",
      name: "郑州航空港区园博美丽新城幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "27",
      text: "园博幸福港湾幼儿园",
      name: "郑州航空港区园博幸福港湾幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "28",
      text: "龙安第一幼儿园",
      name: "郑州航空港区龙安第一幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "29",
      text: "龙祥第二幼儿园",
      name: "郑州航空港区龙祥第二幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "30",
      text: "龙祥第一幼儿园",
      name: "郑州航空港区龙祥第一幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "31",
      text: "园博幸福新城幼儿园",
      name: "郑州航空港区园博幸福新城幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "32",
      text: "明港幼儿园",
      name: "郑州航空港区明港幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "33",
      text: "德瑞嘉园幼儿园",
      name: "郑州航空港区德瑞嘉园幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "34",
      text: "冯堂幼儿园",
      name: "郑州航空港区冯堂幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "35",
      text: "宋家幼儿园",
      name: "郑州航空港区宋家幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "36",
      text: "福和幼儿园",
      name: "郑州航空港区福和幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "37",
      text: "武张幼儿园",
      name: "郑州航空港区武张幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "38",
      text: "郝营幼儿园",
      name: "郑州航空港区郝营幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "39",
      text: "大路张幼儿园",
      name: "郑州航空港区大路张幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "40",
      text: "庙后幼儿园",
      name: "郑州航空港区庙后幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "41",
      text: "蔡庄坡幼儿园",
      name: "郑州航空区蔡庄坡幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "42",
      text: "滹沱张幼儿园",
      name: "郑州航空区滹沱张幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "43",
      text: "高陈幼儿园",
      name: "郑州航空区高陈幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "44",
      text: "八千幼儿园",
      name: "郑州航空港区八千幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "45",
      text: "梅河幼儿园",
      name: "郑州航空港区梅河幼儿园",
      edu: "幼儿园",
      type: "公办",
    },
    {
      key: "46",
      text: "领航学校",
      name: "郑州航空港区领航学校",
      edu: "小学",
      type: "公办",
    },
    {
      key: "47",
      text: "实验小学",
      name: "郑州航空港区实验小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "48",
      text: "慈航路小学",
      name: "郑州航空港区慈航路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "49",
      text: "第十七小学",
      name: "郑州航空港区第十七小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "50",
      text: "第十小学",
      name: "郑州航空港区第十小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "51",
      text: "遵大路小学",
      name: "郑州航空港区遵大路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "52",
      text: "第二小学",
      name: "郑州航空港区第二小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "53",
      text: "如舞路小学",
      name: "郑州航空港区如舞路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "54",
      text: "小李庄小学",
      name: "郑州航空港区小李庄小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "55",
      text: "外国语小学",
      name: "郑州航空港区外国语小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "56",
      text: "太湖路小学",
      name: "郑州航空港区太湖路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "57",
      text: "桥航路小学",
      name: "郑州航空港区桥航路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "58",
      text: "护航路小学",
      name: "郑州航空港区护航路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "59",
      text: "张庄小学",
      name: "郑州航空港区张庄小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "60",
      text: "乐耕小学",
      name: "郑州航空港区乐耕小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "61",
      text: "龙港小学",
      name: "郑州航空港区龙港小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "62",
      text: "冯庄小学",
      name: "郑州航空港区冯庄小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "63",
      text: "单家小学",
      name: "郑州航空港区单家小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "64",
      text: "博雅小学",
      name: "郑州航空港区博雅小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "65",
      text: "明德小学",
      name: "郑州航空港区明德小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "66",
      text: "三官庙小学",
      name: "郑州航空港区三官庙小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "67",
      text: "张马小学",
      name: "郑州航空港区张马小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "68",
      text: "高皇寺小学",
      name: "郑州航空港区高皇寺小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "69",
      text: "湾王小学",
      name: "郑州航空港区湾王小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "70",
      text: "天骄路小学",
      name: "郑州航空港区天骄路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "71",
      text: "思存路小学",
      name: "郑州航空港区思存路小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "72",
      text: "科技一街小学",
      name: "郑州航空港区科技一街小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "73",
      text: "艺术小学",
      name: "郑州航空港区艺术小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "74",
      text: "冯堂第一小学",
      name: "郑州航空港区冯堂第一小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "75",
      text: "楼王小学",
      name: "郑州航空港区楼王小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "76",
      text: "土墙小学",
      name: "郑州航空港区土墙小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "77",
      text: "赵家小学",
      name: "郑州航空港区赵家小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "78",
      text: "冯堂第二小学",
      name: "郑州航空港区冯堂第二小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "79",
      text: "八李小学",
      name: "郑州航空港区八李小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "80",
      text: "武张小学",
      name: "郑州航空港区武张小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "81",
      text: "大路张小学",
      name: "郑州航空港区大路张小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "82",
      text: "福和希望小学",
      name: "郑州航空港区福和希望小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "83",
      text: "高陈小学",
      name: "郑州航空港区高陈小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "84",
      text: "蔡庄坡小学",
      name: "郑州航空港区蔡庄坡小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "85",
      text: "滹沱张小学",
      name: "郑州航空港区滹沱张小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "86",
      text: "庙后小学",
      name: "郑州航空港区庙后小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "87",
      text: "君赵小学",
      name: "郑州航空港区君赵小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "88",
      text: "梅河小学",
      name: "郑州航空港区梅河小学",
      edu: "小学",
      type: "公办",
    },
    {
      key: "89",
      text: "大关庄小学",
      name: "郑州航空港区大关庄小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "90",
      text: "生金李小学",
      name: "郑州航空港区生金李小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "91",
      text: "吕坡小学",
      name: "郑州航空港区吕坡小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "92",
      text: "善水小学",
      name: "郑州航空港区善水小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "93",
      text: "前霍小学",
      name: "郑州航空港区前霍小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "94",
      text: "白庄小学",
      name: "郑州航空港区白庄小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "95",
      text: "河头陈小学",
      name: "郑州航空港区河头陈小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "96",
      text: "沙张小学",
      name: "郑州航空港区沙张小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "97",
      text: "楼刘小学",
      name: "郑州航空港区楼刘小学",
      edu: "小学教学点",
      type: "公办",
    },
    {
      key: "98",
      text: "郑州市第一二一中学",
      name: "郑州市第一二一中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "99",
      text: "郑州市第一二二中学",
      name: "郑州市第一二二中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "100",
      text: "郑州市第一二三中学",
      name: "郑州市第一二三中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "101",
      text: "郑州一中国际航空港实验学校分校",
      name: "郑州一中国际航空港实验学校分校",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "102",
      text: "郑州一中国际航空港实验学校",
      name: "郑州一中国际航空港实验学校",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "103",
      text: "郑州市第一二八中学",
      name: "郑州市第一二八中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "104",
      text: "郑州市第一三二中学",
      name: "郑州市第一三二中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "105",
      text: "郑州市第一二五中学",
      name: "郑州市第一二五中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "106",
      text: "河东七号安置区初级中学",
      name: "郑州航空港区河东七号安置区初级中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "107",
      text: "郑州市第一三〇中学",
      name: "郑州市第一三〇中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "108",
      text: "郑州市第一三一中学",
      name: "郑州市第一三一中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "109",
      text: "郑州市第一二四中学",
      name: "郑州市第一二四中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "110",
      text: "郑州市第一二六中学",
      name: "郑州市第一二六中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "111",
      text: "郑州市第一二七中学",
      name: "郑州市第一二七中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "112",
      text: "郑州市第一二九中学",
      name: "郑州市第一二九中学",
      edu: "初级中学",
      type: "公办",
    },
    {
      key: "113",
      text: "高级中学",
      name: "郑州航空港区高级中学",
      edu: "高级中学",
      type: "公办",
    },
    {
      key: "114",
      text: "实验学校",
      name: "郑州航空港区实验学校",
      edu: "九年一贯制学校",
      type: "公办",
    },
    {
      key: "115",
      text: "益智学校",
      name: "郑州航空港区益智学校",
      edu: "培智学校",
      type: "公办",
    },
    {
      key: "116",
      text: "小龙人幼儿园",
      name: "郑州综保区(港区）小龙人幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "117",
      text: "薛店街幼儿园",
      name: "郑州综保区（港区）薛店街幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "118",
      text: "新港艺术幼儿园",
      name: "郑州航空港经济综合实验区新港艺术幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "119",
      text: "第一小学幼儿园",
      name: "郑州综保区（港区）第一小学幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "120",
      text: "海星幼儿园",
      name: "郑州航空港经济综合实验区海星幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "121",
      text: "金色摇篮幼儿园",
      name: "郑州航空港经济综合实验区金色摇篮幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "122",
      text: "蓝旭幼儿园",
      name: "郑州航空港经济综合实验区蓝旭幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "123",
      text: "赛恩小太阳幼儿园",
      name: "郑州航空港经济综合实验区赛恩小太阳幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "124",
      text: "佳贝特幼儿园",
      name: "郑州航空港经济综合实验区佳贝特幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "125",
      text: "育人宝贝幼儿园",
      name: "郑州航空港经济综合实验区育人宝贝幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "126",
      text: "华立世幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）华立世幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "127",
      text: "未来幼儿园",
      name: "郑州航空港区未来幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "128",
      text: "新畅贝贝幼儿园",
      name: "郑州航空港经济综合实验区新畅贝贝幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "129",
      text: "郑港后宋幼儿园",
      name: "郑州航空港区郑港后宋幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "130",
      text: "英迪国际幼儿园",
      name: "郑州航空港区英迪国际幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "131",
      text: "天伦吉尔幼儿园",
      name: "郑州航空港经济综合实验区天伦吉尔幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "132",
      text: "北师励耘幼儿园",
      name: "郑州航空港经济综合实验区北师励耘幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "133",
      text: "为为童欣幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）为为童欣幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "134",
      text: "荣航幼儿园",
      name: "郑州航空港经济综合实验区荣航幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "135",
      text: "翔之航幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）翔之航幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "136",
      text: "温斯德幼儿园",
      name: "郑州航空港经济综合实验区温斯德幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "137",
      text: "开远幼儿园",
      name: "郑州航空港经济综合实验区开远幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "138",
      text: "京航幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）京航幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "139",
      text: "哈布幼儿园",
      name: "郑州航空港经济综合实验区哈布幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "140",
      text: "艾英格幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）艾英格幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "141",
      text: "马荣凯瑞幼儿园",
      name: "郑州航空港区马荣凯瑞幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "142",
      text: "品格幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）品格幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "143",
      text: "贝尔嘉幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）贝尔嘉幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "144",
      text: "张庄办事处艾培实验幼儿园",
      name: "郑州航空港经济综合实验区（综保区）张庄办事处艾培实验幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "145",
      text: "张庄办事处小龙人幼儿园",
      name: "郑州航空港经济综合实验区（综保区）张庄办事处小龙人幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "146",
      text: "张庄办事处西街幼儿园",
      name: "郑州航空港经济综合实验区（综保区）张庄办事处西街幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "147",
      text: "铭公幼儿园",
      name: "郑州航空港经济综合实验区铭公幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "148",
      text: "慧童幼儿园",
      name: "郑州航空港经济综合实验区慧童幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "149",
      text: "心愿幼儿园",
      name: "郑州航空港经济综合实验区心愿幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "150",
      text: "张庄办事处生金李幼儿园",
      name: "郑州航空港经济综合实验区（综保区）张庄办事处生金李幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "151",
      text: "龙港实验幼儿园",
      name: "郑州航空港区龙港实验幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "152",
      text: "龙港艺术幼儿园",
      name: "郑州航空港区龙港艺术幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "153",
      text: "三官庙家乐幼儿园",
      name: "郑州航空港区三官庙家乐幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "154",
      text: "乐逸幼儿园",
      name: "郑州航空港经济综合实验区乐逸幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "155",
      text: "童真飞翔幼儿园",
      name: "郑州航空港经济综合实验区童真飞翔幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "156",
      text: "睿琛幼儿园",
      name: "郑州航空港区睿琛幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "157",
      text: "童悦未来幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）童悦未来幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "158",
      text: "三官庙幼儿教育基地",
      name: "郑州航空港区三官庙幼儿教育基地",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "159",
      text: "龙王办事处中心幼儿园",
      name: "郑州航空港经济综合实验区（综保区）龙王办事处中心幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "160",
      text: "龙王办事处赵郭李超前幼儿园",
      name: "郑州航空港经济综合实验区（综保区)龙王办事处赵郭李超前幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "161",
      text: "龙王办事处畅想幼儿园",
      name: "郑州航空港经济综合实验区（综保区）龙王办事处畅想幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "162",
      text: "冯堂办事处冯堂中心幼儿园",
      name: "郑州航空港经济综合实验区（综保区）冯堂办事处冯堂中心幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "163",
      text: "睿童乐幼儿园",
      name: "郑州航空港经济综合实验区睿童乐幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "164",
      text: "睿航智慧幼儿园",
      name: "郑州航空港经济综合实验区（郑州新郑综合保税区）睿航智慧幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "165",
      text: "清河办事处大拇指幼儿园",
      name: "郑州航空港经济综合实验区（综保区）清河办事处大拇指幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "166",
      text: "七彩童话幼儿园",
      name: "郑州航空港经济综合实验区七彩童话幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "167",
      text: "敏之睿幼儿园",
      name: "郑州航空港经济综合实验区敏之睿幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "168",
      text: "乐贝多幼儿园",
      name: "郑州航空港经济综合实验区乐贝多幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "169",
      text: "童昕幼儿园",
      name: "郑州航空港经济综合实验区童昕幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "170",
      text: "天之爱幼儿园",
      name: "郑州航空港经济综合实验区天之爱幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "171",
      text: "乐贝馨幼儿园",
      name: "郑州航空港经济综合实验区乐贝馨幼儿园",
      edu: "幼儿园",
      type: "民办",
    },
    {
      key: "172",
      text: "育人高级中学",
      name: "郑州航空港区育人高级中学",
      edu: "高级中学",
      type: "民办",
    },
    {
      key: "173",
      text: "艺书高级中学",
      name: "郑州航空港经济综合实验区艺书高级中学",
      edu: "高级中学",
      type: "民办",
    },
    {
      key: "174",
      text: "郑航实验高级中学",
      name: "郑州航空港区郑航实验高级中学",
      edu: "高级中学",
      type: "民办",
    },
    {
      key: "175",
      text: "郑州航空港育人国际学校",
      name: "郑州航空港育人国际学校",
      edu: "九年一贯制学校",
      type: "民办",
    },
    {
      key: "176",
      text: "英迪国际学校",
      name: "郑州航空港区英迪国际学校",
      edu: "十二年一贯制学校",
      type: "民办",
    },
    {
      key: "177",
      text: "河南省实验学校裕鸿国际学校",
      name: "河南省实验学校裕鸿国际学校",
      edu: "十二年一贯制学校",
      type: "民办",
    },
    {
      key: "178",
      text: "郑州市宇华实验学校",
      name: "郑州市宇华实验学校",
      edu: "完全中学",
      type: "民办",
    },
    {
      key: "179",
      text: "郑州华夏中学",
      name: "郑州华夏中学",
      edu: "完全中学",
      type: "民办",
    },
    {
      key: "180",
      text: "华德中等专业学校",
      name: "郑州航空港经济综合实验区华德中等专业学校",
      edu: "中等技术学校",
      type: "民办",
    },
  ],
  hosEnum: [
    {
      key: "0",
      text: "岐伯山医院",
    },
    {
      key: "19",
      text: "郑大一附院南院区",
    },
    {
      key: "20",
      text: "郑州市第一人民医院南院区",
    },
    {
      key: "1",
      text: "郑州航空港区第二人民医院",
    },
    {
      key: "2",
      text: "张庄中心卫生院",
    },
    {
      key: "3",
      text: "三官庙中心卫生院",
    },
    {
      key: "4",
      text: "八岗卫生院",
    },
    {
      key: "5",
      text: "八千中心卫生院",
    },
    {
      key: "6",
      text: "龙王中心卫生院",
    },
    {
      key: "7",
      text: "冯堂卫生院",
    },
    {
      key: "8",
      text: "郑港社区卫生服务中心",
    },
    {
      key: "9",
      text: "大营卫生院",
    },
    {
      key: "10",
      text: "岗李卫生院",
    },
    {
      key: "11",
      text: "大马卫生院",
    },
    {
      key: "12",
      text: "新港社区卫生服务中心",
    },
    {
      key: "13",
      text: "滨河社区卫生服务中心",
    },
    {
      key: "14",
      text: "银河社区卫生服务中心",
    },
    {
      key: "15",
      text: "滨河社区卫生服务中心（民营）",
    },
    {
      key: "16",
      text: "豫康社区卫生服务中心（民营）",
    },
    {
      key: "17",
      text: "郑港医院",
    },
    {
      key: "18",
      text: "黄海医院",
    },
  ],

  isBuyEnum: [
    {
      key: "0",
      text: "否",
    },
    {
      key: "1",
      text: "是",
    },
  ],

  isRentEnum: [
    {
      key: "0",
      text: "否",
    },
    {
      key: "1",
      text: "是",
    },
  ],

  livingStatusEnum: [
    {
      key: "5",
      text: "居住非港区区域",
    },
    {
      key: "0",
      text: "租住港区安置区",
    },
    {
      key: "1",
      text: "租住港区商品房小区",
    },
    {
      key: "2",
      text: "住港区集体宿舍",
    },
    {
      key: "3",
      text: "租住在港区城中村或乡镇",
    },
    {
      key: "4",
      text: "住港区自有房屋",
    },
  ],
  rentTypeEnum: [
    {
      key: "0",
      text: "独立单间（带卫生间）",
    },
    {
      key: "1",
      text: "套房合租（住套房中的一间）",
    },
    {
      key: "2",
      text: "家庭套房（整套）",
    },
  ],
  houseTypeEnum: [
    {
      key: "0",
      text: "商品房",
    },
    {
      key: "1",
      text: "安置区",
    },
    {
      key: "2",
      text: "城中村/乡镇",
    },
  ],

  rentPrice0Enum: [
    {
      key: "600元以下/月",
      text: "600元以下/月",
    },
    {
      key: "600-800元/月",
      text: "600-800元/月",
    },
    {
      key: "800-1000元/月",
      text: "800-1000元/月",
    },
    {
      key: "1000元以上/月",
      text: "1000元以上/月",
    },
  ],
  rentPrice1Enum: [
    {
      key: "600元以下/月",
      text: "600元以下/月",
    },
    {
      key: "600-800元/月",
      text: "600-800元/月",
    },
    {
      key: "800-1000元/月",
      text: "800-1000元/月",
    },
    {
      key: "1000元以上/月",
      text: "1000元以上/月",
    },
  ],
  rentPrice2Enum: [
    {
      key: "1000元以下/月",
      text: "1000元以下/月",
    },
    {
      key: "1000-1200元/月",
      text: "1000-1200元/月",
    },
    {
      key: "1200-1500元/月",
      text: "1200-1500元/月",
    },
    {
      key: "1500元以上/月",
      text: "1500元以上/月",
    },
  ],
  academicEnum: [
    {
      key: "0",
      text: "专科以下",
    },
    {
      key: "1",
      text: "专科",
    },
    {
      key: "2",
      text: "本科",
    },
    {
      key: "3",
      text: "硕士研究生",
    },
    {
      key: "4",
      text: "博士研究生",
    },
  ],
};
export default enums;
