import request from "@/utils/request";

// 新增人才需求统计
export function addStatistics(data) {
  return request({
    url: "/policy/need/addStatistics",
    method: "POST",
    data,
  });
}

// 编辑人才需求统计
export function editStatistics(data) {
  return request({
    url: "/policy/need/editStatistics",
    method: "POST",
    data,
  });
}

// 根据手机号回显信息
export function getInfoByPhone(params) {
  return request({
    url: "/policy/need/getInfoByPhone",
    method: "get",
    params,
  });
}

