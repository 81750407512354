<template>
  <!-- 人才公寓需求数量统计 -->
  <section class="form-wrapper">
    <van-form ref="form">
      <div class="header card">
        <div class="title">人才公寓需求意向申请表</div>
        <div class="notices">
          <p class="notice-item">*填写说明</p>
          <p class="notice-item">
            1、参与本次信息采集人员，列入优先租赁人才公寓、享受补贴名单，请确保信息真实有效。
          </p>
          <p class="notice-item">
            2、有改善居住条件（租赁单人单间、小夫妻单间、家庭套房）需求的产业人员、各层次人才均可填写。
          </p>
          <p class="notice-item">
            3、已经自行租房的人员，也请参加此次信息采集。
          </p>
          <p class="notice-item">
            4、房源位置、优惠政策、申请方式等将于后续通知，可关注“
            <span class="link" @click="goPublic">河南郑州航空港人才</span>
            ”公众号知悉。
          </p>
        </div>
        <van-field
          v-model.trim="form.usrName"
          label="姓名"
          placeholder="请输入姓名"
          required
          maxlength="20"
        />
        <van-field
          v-model.trim="form.usrPhone"
          label="手机号"
          placeholder="请输入手机号"
          required
          maxlength="11"
          readonly
          disabled
        />

        <van-field
          v-model.trim="form.usrIdCard"
          label="	身份证号"
          placeholder="请输入身份证号"
          required
          maxlength="18"
        />
        <van-field
          :value="academicText"
          clickable
          label="学历"
          placeholder="请选择学历"
          required
          readonly
          @click="showAcademic = true"
        />
        <van-popup v-model="showAcademic" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.academicEnum"
            @cancel="showAcademic = false"
            @confirm="onAcademicConfirm"
          />
        </van-popup>
        <van-field
          :value="cmpyTypeText"
          clickable
          label="工作单位"
          placeholder="请选择工作单位"
          required
          readonly
          @click="showCmpyType = true"
        />
        <van-popup v-model="showCmpyType" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.cmpyTypeEnum"
            @cancel="showCmpyType = false"
            @confirm="onCmpyTypeConfirm"
          />
        </van-popup>
        <van-field
          v-model.trim="form.localtionName"
          label="单位名称"
          placeholder="请输入单位名称"
          required
          v-if="'97' == form.cmpyType"
        />
        <van-field
          v-model.trim="form.localtionName"
          clickable
          label="医院名称"
          placeholder="请选择医院或者卫生院名称"
          required
          readonly
          @click="showHosPopup = true"
          v-if="'98' == form.cmpyType"
        />
        <van-popup v-model="showHosPopup" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.hosEnum"
            @cancel="showHosPopup = false"
            @confirm="onHosConfirm"
          />
        </van-popup>
        <van-field
          v-model.trim="form.localtionName"
          clickable
          label="学校名称"
          placeholder="请选择学校名称"
          type="textarea"
          autosize
          required
          readonly
          @click="showEduPopup = true"
          v-if="'99' == form.cmpyType"
        />
        <van-popup v-model="showEduPopup" position="bottom">
          <van-picker
            show-toolbar
            :columns="eduEnum"
            @cancel="showEduPopup = false"
            @confirm="onEduConfirm"
          />
        </van-popup>
        <area-select
          v-model.trim="form.address"
          :fieldProps="{
            label: '位置',
            required: true,
            placeholder: '请输入企业位置',
          }"
          @poiSelect="(e) => handlePoiSelect(e)"
        ></area-select>

        <van-field
          :value="livingStatusText"
          clickable
          label="目前居住情况"
          placeholder="请选择目前居住情况"
          required
          readonly
          @click="showLivingStatus = true"
        />
        <van-popup v-model="showLivingStatus" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.livingStatusEnum"
            @cancel="showLivingStatus = false"
            @confirm="onLivingStatusConfirm"
          />
        </van-popup>

        <van-field
          :value="houseTypeText"
          clickable
          label="自有房屋类型"
          placeholder="请选择自有房屋类型"
          required
          readonly
          @click="showHouseType = true"
          v-if="'4' == form.livingStatus"
        />
        <van-popup v-model="showHouseType" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.houseTypeEnum"
            @cancel="showHouseType = false"
            @confirm="onHouseTypeConfirm"
          />
        </van-popup>
        <van-field
          :value="isBuyText"
          clickable
          label="近期是否有购房意向"
          placeholder="请选择"
          required
          readonly
          @click="showIsBuy = true"
        />
        <van-popup v-model="showIsBuy" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.isBuyEnum"
            @cancel="showIsBuy = false"
            @confirm="onIsBuyConfirm"
          />
        </van-popup>

        <van-field
          :value="isRentText"
          clickable
          label="近期是否有租房意向"
          placeholder="请选择"
          required
          readonly
          @click="showIsRent = true"
        />
        <van-popup v-model="showIsRent" position="bottom">
          <van-picker
            show-toolbar
            :columns="enums.isRentEnum"
            @cancel="showIsRent = false"
            @confirm="onIsRentConfirm"
          />
        </van-popup>
        <template v-if="'1' == form.isRent">
          <van-field
            :value="rentTypeText"
            clickable
            label="租房需求类型"
            placeholder="请选择"
            required
            readonly
            @click="showRentType = true"
          />
          <van-popup v-model="showRentType" position="bottom">
            <van-picker
              show-toolbar
              :columns="enums.rentTypeEnum"
              @cancel="showRentType = false"
              @confirm="onRentTypeConfirm"
            />
          </van-popup>

          <van-field
            :value="rentPriceText"
            clickable
            label="租房意向价格"
            placeholder="请选择"
            required
            readonly
            @click="showRentPrice = true"
          />
          <van-popup v-model="showRentPrice" position="bottom">
            <!-- 单间价格 -->
            <van-picker
              show-toolbar
              :columns="enums.rentPrice0Enum"
              @cancel="showRentPrice = false"
              @confirm="onRentPrice0Confirm"
              v-if="'0' == form.rentType"
            />
            <!-- 套房合租 -->
            <van-picker
              show-toolbar
              :columns="enums.rentPrice1Enum"
              @cancel="showRentPrice = false"
              @confirm="onRentPrice1Confirm"
              v-if="'1' == form.rentType"
            />
            <!-- 家庭套房 -->
            <van-picker
              show-toolbar
              :columns="enums.rentPrice2Enum"
              @cancel="showRentPrice = false"
              @confirm="onRentPrice2Confirm"
              v-if="'2' == form.rentType"
            />
          </van-popup>
        </template>
      </div>
    </van-form>
  </section>
</template>

<script>
import Schema from "async-validator";
import formRules from "./rules";
import VanFieldSelect from "@/components/VanFieldSelect";
import cloneDeep from "lodash/cloneDeep";
import AreaSelect from "../../components/AreaSelect.vue";
import Cookies from "js-cookie";
import enums from "./enums";

//需求表单
const needForm = {
  address: "",
  cmpyType: "",
  gcj02Lat: "",
  gcj02Lon: "",
  houseType: "",
  isBuy: "",
  isRent: "",
  livingStatus: "",
  rentPrice: "",
  rentType: "",
  localtionName: "",
  usrName: "",
  usrPhone: "",
  academic: "",
  usrIdCard: "",
};
export default {
  data() {
    return {
      form: cloneDeep(needForm),
      showCmpyType: false,
      showHosPopup: false,
      showEduPopup: false,
      showLivingStatus: false,
      showHouseType: false,
      showIsRent: false,
      showIsBuy: false,
      showRentType: false,
      showRentPrice: false,
      showAcademic: false,
      enums,
    };
  },
  props: {
    defaultForm: Object,
  },
  components: { VanFieldSelect, AreaSelect },
  computed: {
    eduEnum() {
      let arr = [
        {
          text: "公办",
          children: [],
        },
        {
          text: "民办",
          children: [],
        },
      ];
      let arrEduGL = [
        {
          text: "幼儿园",
          children: [],
        },
        {
          text: "小学",
          children: [],
        },
        {
          text: "小学教学点",
          children: [],
        },
        {
          text: "初级中学",
          children: [],
        },
        {
          text: "高级中学",
          children: [],
        },
        {
          text: "九年一贯制学校",
          children: [],
        },
        {
          text: "十二年一贯制学校",
          children: [],
        },
        {
          text: "培智学校",
          children: [],
        },
        {
          text: "完全中学",
          children: [],
        },
        {
          text: "中等技术学校",
          children: [],
        },
      ];
      let arrEduSL = JSON.parse(JSON.stringify(arrEduGL));
      // 获取类型分组
      enums.eduEnum.map((item) => {
        switch (item.edu) {
          case "幼儿园":
            item.type === "公办"
              ? arrEduGL[0].children.push(item)
              : arrEduSL[0].children.push(item);
            break;
          case "小学":
            item.type === "公办"
              ? arrEduGL[1].children.push(item)
              : arrEduSL[1].children.push(item);
            break;
          case "小学教学点":
            item.type === "公办"
              ? arrEduGL[2].children.push(item)
              : arrEduSL[2].children.push(item);
            break;
          case "初级中学":
            item.type === "公办"
              ? arrEduGL[3].children.push(item)
              : arrEduSL[3].children.push(item);
            break;
          case "高级中学":
            item.type === "公办"
              ? arrEduGL[4].children.push(item)
              : arrEduSL[5].children.push(item);
            break;
          case "九年一贯制学校":
            item.type === "公办"
              ? arrEduGL[5].children.push(item)
              : arrEduSL[5].children.push(item);
            break;
          case "十二年一贯制学校":
            item.type === "公办"
              ? arrEduGL[6].children.push(item)
              : arrEduSL[6].children.push(item);
            break;
          case "培智学校":
            item.type === "公办"
              ? arrEduGL[7].children.push(item)
              : arrEduSL[7].children.push(item);
            break;
          case "完全中学":
            item.type === "公办"
              ? arrEduGL[8].children.push(item)
              : arrEduSL[8].children.push(item);
            break;
          case "中等技术学校":
            item.type === "公办"
              ? arrEduGL[9].children.push(item)
              : arrEduSL[9].children.push(item);
            break;
          default:
            break;
        }
      });

      arrEduGL = arrEduGL.filter((item) => {
        if (item.children.length > 0) {
          return item;
        }
      });

      arrEduSL = arrEduSL.filter((item) => {
        if (item.children.length > 0) {
          return item;
        }
      });

      arr[0].children = arrEduGL;
      arr[1].children = arrEduSL;
      return arr;
      //
    },
    cmpyTypeText() {
      let needEnum = enums.cmpyTypeEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.cmpyType;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    livingStatusText() {
      let needEnum = enums.livingStatusEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.livingStatus;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },

    houseTypeText() {
      let needEnum = enums.houseTypeEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.houseType;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    isBuyText() {
      let needEnum = enums.isBuyEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.isBuy;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    isRentText() {
      let needEnum = enums.isRentEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.isRent;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    rentTypeText() {
      let needEnum = enums.rentTypeEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.rentType;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    rentPriceText() {
      let needEnum;
      if ("0" == this.form.rentType) {
        needEnum = enums.rentPrice0Enum;
      } else if ("1" == this.form.rentType) {
        needEnum = enums.rentPrice1Enum;
      } else {
        needEnum = enums.rentPrice2Enum;
      }
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.rentPrice;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
    academicText() {
      let needEnum = enums.academicEnum;
      let index = needEnum.findIndex((item) => {
        return item.key == this.form.academic;
      });
      if (-1 == index) {
        return "";
      }
      return needEnum[index].text;
    },
  },
  watch: {
    defaultForm(val) {
      this.form.address = val.address;
      this.form.cmpyType = val.cmpyType;
      this.form.gcj02Lat = val.gcj02Lat;
      this.form.gcj02Lon = val.gcj02Lon;
      this.form.houseType = val.houseType;
      this.form.isBuy = val.isBuy;
      this.form.isRent = val.isRent;
      this.form.livingStatus = val.livingStatus;
      this.form.rentPrice = val.rentPrice;
      this.form.rentType = val.rentType;
      this.form.localtionName = val.localtionName;
      this.form.usrName = val.usrName;
      this.form.academic = val.academic;
      this.form.usrIdCard = val.usrIdCard;
      this.form.bizCode = val.bizCode;
    },
  },
  mounted() {
    const authPhone = Cookies.get("HouseSurveyPhone") || "";
    if (authPhone) {
      this.form.usrPhone = authPhone;
    }
  },
  methods: {
    onCmpyTypeConfirm(value) {
      this.showCmpyType = false;
      if (value.key == this.form.cmpyType) {
        return;
      }
      this.form.cmpyType = value.key;
      this.form.localtionName = "";
    },
    onHosConfirm(value) {
      this.showHosPopup = false;
      if (value.text == this.form.localtionName) {
        return;
      }
      this.form.localtionName = value.text;
    },
    onEduConfirm(value, index) {
      let name =
        this.eduEnum[index[0]].children[index[1]].children[index[2]].name;
      this.showEduPopup = false;
      if (name == this.form.localtionName) {
        return;
      }
      this.form.localtionName = name;
    },
    onLivingStatusConfirm(value) {
      this.form.livingStatus = value.key;
      this.showLivingStatus = false;
    },
    onHouseTypeConfirm(value) {
      this.form.houseType = value.key;
      this.showHouseType = false;
    },
    onIsBuyConfirm(value) {
      this.form.isBuy = value.key;
      this.showIsBuy = false;
    },
    onIsRentConfirm(value) {
      this.form.isRent = value.key;
      this.showIsRent = false;
    },
    onRentTypeConfirm(value) {
      this.form.rentType = value.key;
      this.showRentType = false;
    },
    onRentPrice0Confirm(value) {
      this.form.rentPrice = value.key;
      this.showRentPrice = false;
    },
    onRentPrice1Confirm(value) {
      this.form.rentPrice = value.key;
      this.showRentPrice = false;
    },
    onRentPrice2Confirm(value) {
      this.form.rentPrice = value.key;
      this.showRentPrice = false;
    },
    onAcademicConfirm(value) {
      this.form.academic = value.key;
      this.showAcademic = false;
    },

    handlePoiSelect(poi) {
      this.form.address = `${poi.address} ${poi.name}`;
      this.form.gcj02Lat = `${poi.location.lat}`;
      this.form.gcj02Lon = `${poi.location.lng}`;
    },
    validate() {
      let usedFormRules = cloneDeep(formRules);
      let postData = cloneDeep(this.form);
      if (-1 == ["98", "99", "97"].indexOf(this.form.cmpyType)) {
        usedFormRules.localtionName = [];
        postData.localtionName = "";
      }
      if ("98" == this.form.cmpyType) {
        usedFormRules.localtionName[0].message = "请输入医院或者卫生院名称";
      }
      if ("97" == this.form.cmpyType) {
        usedFormRules.localtionName[0].message = "请输入单位名称";
      }
      if ("4" != this.form.livingStatus) {
        usedFormRules.houseType = [];
        postData.houseType = "";
      }
      if ("1" != this.form.isRent) {
        usedFormRules.rentType = [];
        postData.rentType = "";
        usedFormRules.rentPrice = [];
        postData.rentPrice = "";
      }

      const validator = new Schema(usedFormRules);
      return new Promise((resolve, reject) => {
        validator
          .validate(postData, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...postData,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    goPublic() {
      let ua = navigator.userAgent.toLowerCase();
      let isWXWork = ua.match(/wxwork/i) == "wxwork";
      let isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      if (isWeixin) {
        window.location.href =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMjQxNTI4Mw==#wechat_redirect";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}
.title {
  font-size: 0.36rem;
  margin: 0.3rem 0;
  text-align: center;
  font-weight: bold;
}
.form-wrapper {
  // margin-top: 0.2rem;
  // background: #fff;
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.house-wrapper {
  padding: 0.1rem 0.15rem;
  .house-title {
    margin: 0.3rem 0;
    .add-btn {
      margin-left: 0.2rem;
    }
    // display: flex;
    // justify-content: space-between;
  }
  .house-form {
    padding-left: 0.15rem;
    overflow: auto;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #dfdfdf;
    .del-house-btn {
      display: block;
      // margin-bottom: 0.2rem;
      padding: 0.2rem 0;
    }
  }
  .house-form-title {
    margin: 0.1rem 0;
    display: flex;
    justify-content: space-between;
  }
}
.plot-wrapper {
  // border-bottom: 1px solid #dfdfdf;
  padding: 0.2rem 0;
  margin-bottom: 0.2rem;
  .plot-title {
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
  }
}
.add-btn {
  color: #3c37d2;
}
.del-btn {
  color: red;
}
.notices {
  margin-top: 0.2rem;
  padding: 0.2rem;
  background-color: #fff;
  .notice-item {
    font-size: 0.25rem;
    line-height: 1.8;
    color: #ff0000;
    margin-bottom: 0.15rem;
  }
  .link {
    color: blue;
  }
}
</style>
