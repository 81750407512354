<template>
  <div>
    <template v-if="showForm">
      <Result v-if="showResult" @edit="edit"></Result>
      <div class="wrapper" v-else>
        <div class="form">
          <Form ref="form" :defaultForm="defaultForm" />
        </div>
        <div class="btn">
          <van-button
            :loading="loading"
            type="primary"
            block
            @click="handleSubmit"
          >
            提交
          </van-button>
        </div>
      </div>
    </template>

    <UserForm :visible="showAuth" @success="handleValSuccess" />
  </div>
</template>

<script>
import Form from "./components/Form.vue";
import UserForm from "../components/UserForm.vue";
import AuthMixin from "../mixin/UserAuth";
import Result from "./components/result.vue";
import { Notify } from "vant";

import {
  addStatistics,
  editStatistics,
  getInfoByPhone,
} from "@/api/policy/need";

export default {
  data() {
    return {
      loading: false,
      showResult: false,
      defaultForm: null,
    };
  },
  mixins: [AuthMixin],
  components: { Form, UserForm, Result },
  created() {},
  methods: {
    getDefaultForm() {
      getInfoByPhone({
        phone: this.contactPhone,
      }).then((res) => {
        if (res.data) {
          this.defaultForm = res.data;
          this.showResult = true;
        }
      });
    },
    // 进入修改模式
    edit() {
      this.showResult = false;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      getInfoByPhone({
        phone: this.contactPhone,
      })
        .then((res) => {
          if (res.data) {
            this.defaultForm = res.data;
          }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
        });
    },
    //手机号验证成功
    handleValSuccess(form) {
      if (form.phone) {
        this.suthSuccess(form.phone);
      }
    },
    //提交表单
    handleSubmit() {
      this.$refs.form.validate().then((form) => {
        this.loading = true;
        this.$toast.loading({
          duration: 0,
          message: "提交中",
          forbidClick: true,
        });
        if (!this.defaultForm) {
          addStatistics(form)
            .then((res) => {
              this.$toast.clear();
              this.loading = false;
              this.showResult = true;
            })
            .catch((err) => {
              this.$toast.clear();
              this.loading = false;
            });
        } else {
          editStatistics(form)
            .then((res) => {
              this.$toast.clear();
              this.loading = false;
              this.showResult = true;
            })
            .catch((err) => {
              this.$toast.clear();
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f3f3f3;
  padding: 0.2rem 0.15rem;
}

.form {
  margin-bottom: 0.3rem;
}
</style>
