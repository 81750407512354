const rules = {
  usrName: [
    {
      required: true,
      message: "请输入姓名"
    }
  ],
  usrPhone: [
    {
      required: true,
      message: "请输入联系方式"
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式"
    }
  ],
  usrIdCard: [
    {
      required: true,
      message: "请输入身份证号"
    }, {
      mode: "pattern",
      message: "身份证号格式不正确",
      pattern:
        "^[1-9]\\d{5}(?:18|19|20)\\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\\d|30|31)\\d{3}[\\dXx]$",
      type: "string",
    },
  ],
  academic: [
    {
      required: true,
      message: "请选择学历"
    }
  ],
  cmpyType: [
    {
      required: true,
      message: "请选择企业类型"
    }
  ],
  localtionName: [
    {
      required: true,
      message: "请输入学校名称"
    }
  ],
  livingStatus: [
    {
      required: true,
      message: "请选择目前居住情况"
    }
  ],
  houseType: [
    {
      required: true,
      message: "请选择自有房屋类型"
    }
  ],
  isBuy: [
    {
      required: true,
      message: "请选择是否有购房意向"
    }
  ],
  isRent: [
    {
      required: true,
      message: "请选择是否有租房意向"
    }
  ],
  address: [
    {
      required: true,
      message: "请选择工作位置"
    },
  ],
  rentType: [
    {
      required: true,
      message: "请选择租房类型"
    }
  ],
  rentPrice: [
    {
      required: true,
      message: "请选择租房意向价格"
    }
  ],
};
export default rules;
